import {createAsyncThunk, nanoid} from "@reduxjs/toolkit";
import {
    AccrualOverride,
    Adjustment,
    CallProtected,
    RulesSet,
    ScheduledAdjustment,
    sCurveOverride,
    Valuation,
    Version
} from "../../types/valuationModelTypes";
import {addNotification} from "../notifications/notificationSlice";
import {
    addDays,
    checkDateBetween,
    checkDateSame,
    formatDate,
    getLastBusinessDayPreviousMonth
} from "../../utils/DateUtils";
import {
    apiDeleteRequests,
    apiGetRequests, apiPatchRequests, apiPostRequests, apiPutRequests,
    multipleAsyncGetRequest,
    multipleAsyncPostRequest
} from "../apiUtils";
import {RootState} from "../store";
import {transformAdjustmentsObjectToList, transformValuationsObjectToList} from "../../utils/valuationUtils";
import {SaveStatus} from "../../types/valuationModelEnums";
import {GetThunkAPI} from "@reduxjs/toolkit/dist/createAsyncThunk";

/**
 * Thunk functions for Versions
 */

// Initial Data load
export const versionsInitialLoad = createAsyncThunk('version/initialLoad', async (_, thunkAPI) => {
    try {
        // Retrieve all Versions
        const versions = await apiGetRequests('valuation-model/versions');

        // Set new version to yesterday
        let valuationDate = addDays(new Date(), -1);

        if (valuationDate.getDay() === 0) valuationDate = addDays(valuationDate, -2);
        if (valuationDate.getDay() === 6) valuationDate = addDays(valuationDate, -1);

        let version;

        if (checkDateSame(valuationDate, versions[versions.length - 1].valuationDate)) {
            version = versions[versions.length - 1]
        } else {
            const setIds = await getNewVersionDataSets(valuationDate, thunkAPI)

            version = {
                id: nanoid(),
                name: "(New/Unsaved)",
                valuationDate: valuationDate.getTime(),
                previousDate: versions[versions.length - 1].valuationDate,
                creationTimestamp: null,
                modifiedTimestamp: null,
                published: false,
                previousVersionId: versions[versions.length - 1].id,
                status: SaveStatus.NEW,
                ...setIds
            }
        }

        // Retrieve all Versions if any for yesterday
        const versionsOnDate = await apiGetRequests(`valuation-model/versions?date=${formatDate(version.valuationDate, 'yyyy-MM-dd')}`, thunkAPI)

        // Add new version to list
        if (version.status === SaveStatus.NEW) {
            versionsOnDate.push(version)
        }

        return {
            versions,
            versionsOnDate,
            version
        }

    } catch (error) {
        let message = 'Problem occurred loading version';
        if (error instanceof Error && error.message !== 'AUTH_ERROR') {
            message = `Error: ${error.message}`;
            thunkAPI.dispatch(addNotification(message, 'error'));
        }
        console.log(error)
        return thunkAPI.rejectWithValue(message);
    }
})

export const changeVersionsDate = createAsyncThunk('version/changeDate', async (date: Date | number | string, thunkAPI) => {
    try {
        // Retrieve Versions On Date
        const versionsOnDate: Array<Version> = await apiGetRequests(`valuation-model/versions?date=${formatDate(date, 'yyyy-MM-dd')}`, thunkAPI);
        let version: Version | null;
        // Catch if no values on date
        if (versionsOnDate.length === 0) {
            // Check if date between today and last version
            const yesterday = addDays(new Date(), -1);
            const state = thunkAPI.getState() as RootState;
            const lastVersion = state.version.versions[state.version.versions.length - 1]
            const lastPub = state.version.versions[state.version.versions.length - 1].valuationDate;

            // If it is, initialise new version
            if (lastPub && checkDateBetween(date, lastPub, yesterday)) {
                const setIds = await getNewVersionDataSets(new Date(date), thunkAPI)
                version = {
                    id: nanoid(),
                    name: "(New/Unsaved)",
                    valuationDate: new Date(date),
                    previousDate: lastPub,
                    creationTimestamp: null,
                    modifiedTimestamp: null,
                    published: false,
                    previousVersionId: lastVersion.id as number,
                    status: SaveStatus.NEW,
                    ...setIds
                }

                return {
                    version,
                    versionsOnDate: [version]
                }
            } else { // Otherwise send warning and don't update
                thunkAPI.dispatch(addNotification('No versions found on date', 'warning'));
                version = null;
            }

        } else {
            version = versionsOnDate.find(v => v.published) || versionsOnDate[0];
        }
        return {
            version,
            versionsOnDate
        }
    } catch (error) {
        let message = 'Problem occurred loading version';
        if (error instanceof Error && error.message !== 'AUTH_ERROR') {
            message = `Error: ${error.message}`;
            thunkAPI.dispatch(addNotification(message, 'error'));
        }
        console.log(error)
        return thunkAPI.rejectWithValue(message);
    }
})

async function getNewVersionDataSets(versionDate: Date, thunkAPI: GetThunkAPI<any>) {
    try {
        const date = formatDate(versionDate, 'yyyy-MM-dd')
        const lastDayOfPrevMonth = formatDate(getLastBusinessDayPreviousMonth(versionDate), 'yyyy-MM-dd')

        let curves = await apiGetRequests(`external-data/curve?date=${date}`, thunkAPI);
        let bbsw = await apiGetRequests(`external-data/bbsw?date=${date}`, thunkAPI)
        let fx = await apiGetRequests(`external-data/fx?date=${lastDayOfPrevMonth}`, thunkAPI)

        return {
            curveId: curves.id,
            bbswId: bbsw.id,
            fxId: fx.id
        }
    } catch (error) {
        throw error;
    }
}

/**
 * Save as a new Version
 */
export const createVersion = createAsyncThunk('version/createVersion', async (name: string, thunkAPI) => {
    try {
        const state: RootState = thunkAPI.getState() as RootState;
        const newVersion: any = {
            ...state.version.version,
            published: false,
            name,
        }

        if (newVersion) {
            newVersion.valuationDate = formatDate(newVersion.valuationDate, 'yyyy-MM-dd');
            newVersion.previousDate = formatDate(newVersion.previousDate, 'yyyy-MM-dd');

            const version = await apiPostRequests(`valuation-model/versions`, {version: newVersion}, thunkAPI)

            // SAVE VALUATIONS
            const valuations = (transformValuationsObjectToList(state.valuationModel.valuationModelData.valuations)).map(v => ({
                ...v, versionId: version.versionId,
                valuationDate: formatDate(v.valuationDate, 'yyyy-MM-dd'),
                startDate: formatDate(v.startDate, 'yyyy-MM-dd'),
                maturity: formatDate(v.maturity, 'yyyy-MM-dd'),

            }));
            // SAVE ADJUSTMENTS
            const adjustments = (transformAdjustmentsObjectToList(state.valuationModel.valuationModelData.adjustments)).map(a => ({
                ...a, versionId: version.versionId,
                valuationDate: formatDate(a.valuationDate, 'yyyy-MM-dd'),
            }));

            await multipleAsyncPostRequest({
                valuationsQuery: {
                    query: `valuation-model/versions/${version.versionId}/valuations`,
                    body: {valuations}
                },
                adjustmentsQuery: {
                    query: `valuation-model/versions/${version.versionId}/adjustments`,
                    body: {adjustments}
                },
            }, thunkAPI)

            const rulesSet = {
                callProtected: state.valuationModel.valuationModelData.callProtected,
                curveOverrides: state.valuationModel.valuationModelData.sCurveOverride,
                accrualOverrides: state.valuationModel.valuationModelData.accruedOverrides,
                scheduledAdjustments: state.valuationModel.valuationModelData.manualAccrual
            }

            await saveAndEditRules(rulesSet, thunkAPI);

            const {
                versions,
                versionsOnDate
            } = await multipleAsyncGetRequest({
                versions: 'valuation-model/versions',
                versionsOnDate: `valuation-model/versions?date=${formatDate(newVersion.valuationDate, 'yyyy-MM-dd')}`
            }, thunkAPI)

            return {
                versions: versions as Array<Version>,
                versionsOnDate: versionsOnDate as Array<Version>,
                version: versionsOnDate.find((v: {
                    id: any;
                }) => v.id === version.versionId) || versions[versions.length - 1] as Version
            }
        } else {
            new Error('Version not found')
        }
    } catch (error) {
        let message = 'Problem occurred creating version';
        if (error instanceof Error && error.message !== 'AUTH_ERROR') {
            message = `Error: ${error.message}`;
            thunkAPI.dispatch(addNotification(message, 'error'));
        }
        console.log(error);
        return thunkAPI.rejectWithValue(message);
    }
})

/**
 * Saves version
 */
export const saveVersion = createAsyncThunk('version/saveVersion', async (name: string, thunkAPI) => {
    try {
        const state: RootState = thunkAPI.getState() as RootState;
        const newVersion: any = {
            ...state.version.version,
            name,
        }

        if (newVersion) {
            await apiPutRequests(`valuation-model/versions/${newVersion.id}`, {version: newVersion}, thunkAPI)
            await saveVersionCommon(state, newVersion, thunkAPI);
            return await getRefreshedVersions(newVersion.id, newVersion.valuationDate, thunkAPI);
        } else {
            new Error('Version not found')
        }
    } catch (error) {
        let message = 'Problem occurred saving version';
        if (error instanceof Error && error.message !== 'AUTH_ERROR') {
            message = `Error: ${error.message}`;
            thunkAPI.dispatch(addNotification(message, 'error'));
        }
        console.log(error);
        return thunkAPI.rejectWithValue(message);
    }
})

/**
 * Publishes version or saves and publishes if new version
 */
export const publishVersion = createAsyncThunk('version/publishVersion', async (name: string, thunkAPI) => {
    try {
        const state: RootState = thunkAPI.getState() as RootState;
        let newVersion: any = {
            ...state.version.version,
            name,
        }

        if (newVersion) {
            if (newVersion.status === SaveStatus.NEW) {
                newVersion.valuationDate = formatDate(newVersion.valuationDate, 'yyyy-MM-dd');
                newVersion.previousDate = formatDate(newVersion.previousDate, 'yyyy-MM-dd');
                const version = await apiPostRequests(`valuation-model/versions`, {version: newVersion}, thunkAPI);
                newVersion.id = version.versionId;
            } else {
                await apiPutRequests(`valuation-model/versions/${newVersion.id}`, {version: newVersion}, thunkAPI);
            }
            await saveVersionCommon(state, newVersion, thunkAPI);
            await apiPatchRequests(`valuation-model/versions/${newVersion.id}`, {}, thunkAPI);
            return await getRefreshedVersions(newVersion.id, newVersion.valuationDate, thunkAPI);
        } else {
            new Error('Version not found')
        }
    } catch (error) {
        let message = 'Problem occurred publishing version';
        if (error instanceof Error && error.message !== 'AUTH_ERROR') {
            message = `Error: ${error.message}`;
            thunkAPI.dispatch(addNotification(message, 'error'));
        }
        console.log(error);
        return thunkAPI.rejectWithValue(message);
    }
})

// Shared function for saving model data
async function saveVersionCommon(state: RootState, newVersion: Version, thunkAPI: GetThunkAPI<any>) {
    // RETRIEVE VALUATIONS AND ADJUSTMENTS
    const valuations = (transformValuationsObjectToList(state.valuationModel.valuationModelData.valuations)).map(v => ({
        ...v, versionId: newVersion.id,
        valuationDate: formatDate(v.valuationDate, 'yyyy-MM-dd'),
        startDate: formatDate(v.startDate, 'yyyy-MM-dd'),
        maturity: formatDate(v.maturity, 'yyyy-MM-dd'),
    })) as unknown as Array<Valuation>;
    const adjustments = (transformAdjustmentsObjectToList(state.valuationModel.valuationModelData.adjustments)).map(a => ({
        ...a, versionId: newVersion.id,
        valuationDate: formatDate(a.valuationDate, 'yyyy-MM-dd'),
    })) as unknown as Array<Adjustment>;

    const changedValuations = {
        new: valuations.filter(v => v.status === SaveStatus.NEW),
        update: valuations.filter(v => v.status === SaveStatus.EDITED),
        delete: valuations.filter(v => v.status === SaveStatus.REMOVED)
    }

    const changedAdjustments = {
        new: adjustments.filter(a => a.status === SaveStatus.NEW),
        update: adjustments.filter(a => a.status === SaveStatus.EDITED),
        delete: adjustments.filter(a => a.status === SaveStatus.REMOVED)
    }

    if (changedValuations.new.length > 0) await apiPostRequests(`valuation-model/versions/${newVersion.id}/valuations`, {valuations: changedValuations.new}, thunkAPI);
    if (changedAdjustments.new.length > 0) await apiPostRequests(`valuation-model/versions/${newVersion.id}/adjustments`, {adjustments: changedAdjustments.new}, thunkAPI);

    if (changedValuations.update.length > 0) await apiPutRequests(`valuation-model/versions/${newVersion.id}/valuations`, {valuations: changedValuations.update}, thunkAPI);
    if (changedAdjustments.update.length > 0) await apiPutRequests(`valuation-model/versions/${newVersion.id}/adjustments`, {adjustments: changedAdjustments.update}, thunkAPI);

    if (changedValuations.delete.length > 0) await apiDeleteRequests(`valuation-model/versions/${newVersion.id}/valuations`, {valuations: changedValuations.delete}, thunkAPI);
    if (changedAdjustments.delete.length > 0) await apiDeleteRequests(`valuation-model/versions/${newVersion.id}/adjustments`, {adjustments: changedAdjustments.delete}, thunkAPI);

    const rulesSet = {
        callProtected: state.valuationModel.valuationModelData.callProtected,
        curveOverrides: state.valuationModel.valuationModelData.sCurveOverride,
        accrualOverrides: state.valuationModel.valuationModelData.accruedOverrides,
        scheduledAdjustments: state.valuationModel.valuationModelData.manualAccrual
    }

    await saveAndEditRules(rulesSet, thunkAPI);
}

// Refresh version data
async function getRefreshedVersions(versionId: number, valuationDate: number | Date, thunkAPI: GetThunkAPI<any>) {
    const {
        versions,
        versionsOnDate
    } = await multipleAsyncGetRequest({
        versions: 'valuation-model/versions',
        versionsOnDate: `valuation-model/versions?date=${formatDate(valuationDate, 'yyyy-MM-dd')}`
    }, thunkAPI)

    const version = versionsOnDate.find((v: {
        id: any;
    }) => v.id === versionId) || versions[versions.length - 1] as Version

    return {
        versions: versions as Array<Version>,
        versionsOnDate: versionsOnDate as Array<Version>,
        version
    }
}

async function saveAndEditRules(rules: RulesSet, thunkAPI: GetThunkAPI<any>) {

    const changes: {
        [x: string]: {
            curveOverrides: Array<sCurveOverride>,
            accrualOverrides: Array<AccrualOverride>,
            callProtected: Array<CallProtected>,
            scheduledAdjustments: Array<ScheduledAdjustment>
        },
    } = {
        create: {
            curveOverrides: [],
            accrualOverrides: [],
            callProtected: [],
            scheduledAdjustments: []
        },
        update: {
            curveOverrides: [],
            accrualOverrides: [],
            callProtected: [],
            scheduledAdjustments: []
        },
        delete: {
            curveOverrides: [],
            accrualOverrides: [],
            callProtected: [],
            scheduledAdjustments: []
        },
    }

    let create = false;
    let update = false;
    let remove = false;

    rules.curveOverrides.forEach(co => {
        switch (co.status) {
            case SaveStatus.NEW:
                create = true;
                changes.create.curveOverrides.push(co);
                break;
            case SaveStatus.EDITED:
                update = true;
                changes.update.curveOverrides.push(co);
                break;
            case SaveStatus.REMOVED:
                remove = true;
                changes.delete.curveOverrides.push(co);
                break;
            default:
                break;
        }
    })

    rules.accrualOverrides.forEach(ao => {
        switch (ao.status) {
            case SaveStatus.NEW:
                create = true;
                changes.create.accrualOverrides.push(ao);
                break;
            case SaveStatus.EDITED:
                update = true;
                changes.update.accrualOverrides.push(ao);
                break;
            case SaveStatus.REMOVED:
                remove = true;
                changes.delete.accrualOverrides.push(ao);
                break;
            default:
                break;
        }
    })

    rules.callProtected.forEach(cp => {
        switch (cp.status) {
            case SaveStatus.NEW:
                create = true;
                changes.create.callProtected.push(cp);
                break;
            case SaveStatus.EDITED:
                update = true;
                changes.update.callProtected.push(cp);
                break;
            case SaveStatus.REMOVED:
                remove = true;
                changes.delete.callProtected.push(cp);
                break;
            default:
                break;
        }
    })


    rules.scheduledAdjustments.forEach(sa => {
        const adjustment = {
            ...sa,
            startDate: formatDate(sa.startDate, 'yyyy-MM-dd'),
            endDate: sa.endDate ? formatDate(sa.endDate, 'yyyy-MM-dd') : sa.endDate,
        } as unknown as ScheduledAdjustment
        switch (adjustment.status) {
            case SaveStatus.NEW:
                create = true;
                changes.create.scheduledAdjustments.push(adjustment);
                break;
            case SaveStatus.EDITED:
                update = true;
                changes.update.scheduledAdjustments.push(adjustment);
                break;
            case SaveStatus.REMOVED:
                remove = true;
                changes.delete.scheduledAdjustments.push(adjustment);
                break;
            default:
                break;
        }
    })

    if (create) {
        await apiPostRequests('valuation-model/rules', {rules: changes.create}, thunkAPI)
    }
    if (update) {
        await apiPutRequests('valuation-model/rules', {rules: changes.update}, thunkAPI)
    }
    if (remove) {
        await apiDeleteRequests('valuation-model/rules', {rules: changes.delete}, thunkAPI)
    }
}