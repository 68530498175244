import {useEffect} from "react";
import {Navigate, Route, Routes} from 'react-router-dom'
// Components
import {DashboardLayout, MinimalLayout} from "../layouts";
import {AuthenticationCheck, RequireAuth} from "./routerUtils";
// Pages
import {Logout, ValuationModel, Pending, NotFound, RestrictedAccess} from "../Pages";
// Store
import {useAppDispatch, useAppSelector} from "../store/store";
import {checkAppAccess, isAuthenticated} from "../store/user/userSelector";
import useNotifier from "../hooks/useNotifier";
import {checkUserAuth} from "../store/user/userFunction";


/**
 * Handles the Routing for the application
 *
 * Protect routes  from unauthorised access
 * If user has logged in reauthenticates users and redirects to page
 *
 */
export default function Router() {
    const dispatch = useAppDispatch();

    const authenticated = useAppSelector(state => isAuthenticated(state));

    const access = useAppSelector(state => checkAppAccess(state, 'valuation_model'))

    // Check if Active user session
    useEffect(() => {
        if (!authenticated) {
            dispatch(checkUserAuth())
        }
    }, [authenticated, dispatch])

    // Introducers Notifiers
    useNotifier();

    const location = window.location.href;

    return (
        <Routes>
            <Route path='/logout' element={<MinimalLayout/>} >
                <Route path='/logout' element={<Logout />} />
            </Route>

            <Route path='/vm' element={<RequireAuth authenticated={authenticated} access={access}><DashboardLayout access={access}/></RequireAuth>} >
                <Route path='/vm' element={<Navigate to={'/vm/home'} replace={true} />} />
                <Route path='/vm/*' element={<ValuationModel />} />
                {/*    PUT ROUTES HERE FOR VM */}
            </Route>

            <Route path='/restricted' element={<RequireAuth authenticated={authenticated} access={access} ><DashboardLayout access={access}/></RequireAuth>} >
                <Route path='/restricted' element={<RestrictedAccess />} />
            </Route>


            <Route path='/' element={<AuthenticationCheck authenticated={authenticated}><MinimalLayout/></AuthenticationCheck>}>
                <Route path='/' element={<Pending location={location} />}/>
                <Route path='*' element={<NotFound />} />
            </Route>
        </Routes>
    )
}