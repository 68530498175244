// @mui
import {Container, Grid, Typography} from '@mui/material';
// components
// Store
import {useAppSelector} from "../../store/store";
import {getValuationModelLoading} from "../../store/valuationModel/selectors/generalSelectors";
// Local imports
import {
    ValuationColumns, valuationTableSelector
} from "../../store/valuationModel/selectors/portfolioSelector";
import VersionConfigBar from "./VersionConfigBar";
import {Page} from "../../layouts/components";
import {DataTable} from "../../components";

export default function ValuationSummary() {
    const valuations = useAppSelector(state => valuationTableSelector(state));
    const loading = useAppSelector(state => getValuationModelLoading(state));

    return (
        <Page title="Metrics - Valuation Model - Summary">
            <Container maxWidth={false} sx={{maxWidth: '100%', height: 0.99, paddingTop: 2}}>
                <Grid container direction='column' sx={{height: 1}}>
                    <Grid container direction='row' justifyContent='space-between'>
                        <Typography variant="h4" sx={{mb: 3, color: 'primary.main'}}>
                            Valuation Summary
                        </Typography>
                        <VersionConfigBar/>
                    </Grid>
                    <Grid item container sx={{width: '100%', flex: 1}} direction='column'>
                        <Grid item sx={{flex: 1, pb: 1}}>
                            <DataTable
                                title={'Valuations'}
                                columns={ValuationColumns}
                                data={valuations}
                                loading={loading}

                                sort={{field: 'tranche_id', sort: 'asc'}}
                                search
                                showColumns
                                showFilter
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
}