// Imports
import {nanoid, PayloadAction} from "@reduxjs/toolkit";
import {SliceCaseReducers, ValidateSliceCaseReducers} from "@reduxjs/toolkit/dist/createSlice";
// Local Imports
import {ValuationModelState} from "../valuationModelSlice";
import {Adjustment} from "../../../types/valuationModelTypes";
import {checkObjectChanged} from "../../../utils/generalUtils";
import {runValuationReCalc} from "../valuationModelFunctions";
import {AdjustmentType, SaveStatus} from "../../../types/valuationModelEnums";

const adjustmentsReducer: ValidateSliceCaseReducers<ValuationModelState, SliceCaseReducers<ValuationModelState>> = {
    // Add Single Adjustment
    addSingleAdjustment: {
        prepare(adjustment: any) {
            return {
                payload: {
                    ...adjustment,
                    id: nanoid(),
                    status: SaveStatus.NEW,
                    isManual: true,
                }
            }
        },
        reducer(state, action: PayloadAction<Adjustment>) {
            const {trancheId, fund} = action.payload;
            if (!state.valuationModelData.adjustments[trancheId]) {
                state.valuationModelData.adjustments[trancheId] = {};
            }
            if (!state.valuationModelData.adjustments[trancheId][fund]) {
                state.valuationModelData.adjustments[trancheId][fund] = []
            }
            state.valuationModelData.adjustments[trancheId][fund] = [...state.valuationModelData.adjustments[trancheId][fund], action.payload]

            runValuationReCalc(state, trancheId, fund);
        }
    },
    // Add Multiple Adjustment
    addMultipleAdjustments: {
        prepare(payload: {adjustment: any, trancheId: number, valuationDate: number | Date}) {
            const {adjustment, trancheId, valuationDate} = payload;
            const common = {
                trancheId: trancheId,
                valuationDate: valuationDate,
                transactionType: adjustment.transactionType,
                isManual: true,
                comment: adjustment.comment,
                status: SaveStatus.NEW
            }
            const adjustments: Array<Adjustment> = []

            payload.adjustment.funds.forEach((fund: any) => {
                if ((fund.amount !== 0) || common.transactionType === AdjustmentType.WRITE_DOWN) {
                    adjustments.push({
                        ...common,
                        id: nanoid(),
                        fund: fund.fund,
                        amount: fund.amount
                    })
                }
            })
            return {
                payload: {
                    trancheId,
                    adjustments
                }
            }
        },
        reducer(state, action: PayloadAction<{ trancheId: number, adjustments: Array<Adjustment> }>) {
            const {trancheId, adjustments} = action.payload;
            const tranche = state.valuationModelData.adjustments[trancheId] || {};
            const funds = state.valuationModelData.funds.map(f => f.label);
            adjustments.forEach(adjustment => {
                if (funds.includes(adjustment.fund)) {
                    if (!tranche[adjustment.fund]) {
                        tranche[adjustment.fund] = []
                    }
                    tranche[adjustment.fund].push(adjustment)
                    runValuationReCalc(state, trancheId, adjustment.fund);
                }
            })
            state.valuationModelData.adjustments[trancheId] = tranche;
        }
    },
    // EDIT ADJUSTMENT
    editAdjustment: (state, action) => {
        const {trancheId, fund, id} = action.payload;
        const adjustments = state.valuationModelData.adjustments[trancheId][fund];

        if (adjustments) {
            const index = adjustments.findIndex(a => a.id === id && a.status !== SaveStatus.REMOVED);
            if (index !== -1) {
                let changed = false;
                action.payload.isManual = true;
                if (adjustments[index].status !== SaveStatus.NEW) {
                    const existing = adjustments[index];
                    changed = (checkObjectChanged(existing, action.payload, ['transactionType', 'amount', 'comment']));
                    if (changed) {
                        action.payload.status = SaveStatus.EDITED;
                        state.valuationModelData.adjustments[trancheId][fund][index] = action.payload;
                    }
                } else {
                    state.valuationModelData.adjustments[trancheId][fund][index] = action.payload;
                }
                runValuationReCalc(state, trancheId, adjustments[index].fund);
            }
        }
    },
    // REMOVE ADJUSTMENT
    removeAdjustment: (state, action: PayloadAction<{ id: number, trancheId: number, fund: string }>) => {
        const {trancheId, fund, id} = action.payload;
        const adjustments = state.valuationModelData.adjustments[trancheId][fund];

        if (adjustments) {
            const index = adjustments.findIndex(a => a.id === id && a.status !== SaveStatus.REMOVED);
            if (index !== -1) {
                if (adjustments[index].status !== SaveStatus.NEW) {
                    state.valuationModelData.adjustments[trancheId][fund][index].status = SaveStatus.REMOVED;
                } else {
                    adjustments.splice(index, 1);
                    state.valuationModelData.adjustments[trancheId][fund] = adjustments;
                }
                runValuationReCalc(state, trancheId, fund);
            }
        }
    }
}

export default adjustmentsReducer;