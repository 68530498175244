// MUI
import {
    Calculate as CalculateIcon,
    CurrencyExchange as CurrencyExchangeIcon,
    Difference as DifferenceIcon,
    UploadFile as UploadFileIcon,
    RequestPage as RequestPageIcon,
    Article as ArticleIcon,
    FilterDrama as FilterDramaIcon
} from '@mui/icons-material';
// Components
import { Item } from './NavSection';

// ----------------------------------------------------------------------

const navConfig: Item[] = [
    {
        title: 'Summary',
        path: '/vm/home',
        icon: <ArticleIcon />,
    },
    {
        title: 'Valuation Worksheet',
        path: '/vm/worksheet',
        icon: <CalculateIcon />,
    },
    {
        title: 'Valuation Export',
        path: '/vm/export',
        icon: <RequestPageIcon />,
    },
    {
        title: 'Daily Adjustments',
        path: '/vm/adjustmentsExport',
        icon: <CurrencyExchangeIcon />,
    },
    {
        title: 'Bulk Transfer Upload',
        path: '/vm/bulkUpload',
        icon: <UploadFileIcon />,
    },
    {
        title: 'Exceptions',
        path: '/vm/exceptions',
        icon: <DifferenceIcon />,
    },
    {
        title: 'Data',
        path: '/vm/dataExport',
        icon: <FilterDramaIcon />,
    }
];

export default navConfig;
