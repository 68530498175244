import {useEffect, useState} from "react";
import _ from "lodash";
import {Formik, FormikProps, validateYupSchema, yupToFormErrors} from "formik";
import * as Yup from 'yup';
import {AxcessLoanCompare} from "../../../../../../types/valuationModelTypes";
// MUI
import {Box, Button, Grid, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
// Components
import {FormInput} from "../../../../../../components";
import {InputTypes} from "../../../../../../types/InputTypes";
// Store
import {getValuationFunds} from "../../../../../../store/valuationModel/selectors/generalSelectors";
import {useAppDispatch, useAppSelector} from "../../../../../../store/store";
import {AdjustmentType, AdjustmentTypeFields} from "../../../../../../types/valuationModelEnums";
import {fCurrency, fPercent} from "../../../../../../utils/formatNumber";
import {getValuationDate} from "../../../../../../store/version/versionSelector";
import {
    addMultipleAdjustments,
    addMultipleScheduledAdjustments
} from "../../../../../../store/valuationModel/valuationModelSlice";
import {addValues} from "../../../../../../utils/mathUtil";
import {determineAdjustmentDiscountPremium} from "./index";


interface FundFee {
    fund: string,
    percentage: number,
    commitment: number,
    amount: number,
    valuation: boolean,
}

interface TrancheFeeValueProps {
    transactionType: '' | AdjustmentType,
    scheduled: boolean,
    startDate: number | string | Date | null,
    endDate: number | string | Date | null,
    total: number,
    comment: string,
    funds: Array<FundFee>,
    commitment: number
}

const initialValues: TrancheFeeValueProps = {
    transactionType: '',
    scheduled: false,
    startDate: null,
    endDate: null,
    total: 0,
    comment: '',
    funds: [],
    commitment: 0
}

const TrancheFeeSchema = Yup.object().shape({
    transactionType: Yup.string().required('Adjustment/Fee Type is required'),
    total: Yup.number()
        .test({
            name: 'total-exceeds-total',
            message: 'Total of funds fee does not equal total fee.',
            test: function (value) {
                // @ts-ignore
                const funds: Array<FundFee> = this.options.context.funds;

                let total = 0;

                funds.forEach((fund) => {
                    total += fund.amount
                });

                // return (value === total)
                return Math.abs(addValues(value || 0, -total)) < 0.1

            }
        }),
    funds: Yup.array().of(Yup.object().shape({
        amount: Yup.number()
            .test({
                name: 'fund-exceed-total',
                message: 'Fund fee cannot exceed total fee',
                test: function (value) {
                    // @ts-ignore
                    const {total} = this.options.context;
                    return (value !== undefined && (Math.abs(total) >= Math.abs(value)));
                }
            })
    })),
    endDate: Yup.date().nullable().min(Yup.ref('startDate'), 'End Date must be greater than the start Date'),
    comment: Yup.string().required('Comment is required')
})

const TrancheFeeForm = ({trancheId, tranche, setOpen}: {
    trancheId: number,
    tranche: AxcessLoanCompare,
    setOpen: (open: boolean) => void
}) => {
    const dispatch = useAppDispatch();

    const funds = useAppSelector(state => getValuationFunds(state));
    const valuationDate = useAppSelector(state => getValuationDate(state))

    const values = {
        ...initialValues,
        commitment: tranche.commitment,
        funds: _.cloneDeep(tranche.funds).sort((a, b) => (a.fund > b.fund) ? 1 : -1).map((fund) => {
            return {
                fund: fund.fund,
                percentage: fund.percentage,
                commitment: fund.commitment,
                amount: 0,
                valuation: funds.includes(fund.fund)
            }
        })
    }

    return (
        <Box sx={{width: '100%'}}>
            <Formik
                initialValues={values}
                onSubmit={(values) => {
                    if (values.scheduled) {
                        dispatch(addMultipleScheduledAdjustments({
                            adjustment: values,
                            trancheId,
                            valuationDate,
                        }))
                    } else {
                        dispatch(addMultipleAdjustments({
                            adjustment: values,
                            trancheId,
                            valuationDate,
                        }))
                    }
                    setOpen(false)
                }}
                validate={(values) => {
                    try {
                        validateYupSchema(values, TrancheFeeSchema, true, values);
                    } catch (err) {
                        return yupToFormErrors(err)
                    }
                }}
            >
                {(props: FormikProps<any>) => {
                    const {
                        handleSubmit,
                        values
                    } = props;

                    return (
                        <>
                            <form onSubmit={handleSubmit}>
                                <Grid item container direction='row'>
                                    <FormInput
                                        id='transactionType'
                                        label='Adjustment/Fee Type'
                                        fieldType={InputTypes.SELECTION}
                                        layout={{xs: 6, md: 6, lg: 6}}
                                        values={AdjustmentTypeFields.filter(a => a.value !== AdjustmentType.PL_ACCRUAL && a.value !== AdjustmentType.NET_REPAYMENT_PL)}

                                        size='small'
                                    />
                                    { values.transactionType !== AdjustmentType.WRITE_DOWN &&
                                        <>
                                            <FormInput
                                                id='scheduled'
                                                label='Schedule for Future'
                                                fieldType={InputTypes.CHECKBOX}
                                                layout={{xs: 6, md: 6, lg: 6}}
                                                size='small'
                                            />
                                            {values.scheduled &&
                                                <>
                                                    <FormInput
                                                        id='startDate'
                                                        label='Start Date'
                                                        fieldType={InputTypes.DATE}
                                                        layout={{xs: 6, md: 6, lg: 6}}
                                                        minDate={valuationDate ? new Date(new Date(valuationDate).setHours(0,0,0,0)) : new Date()}

                                                        size='small'
                                                    />
                                                    <FormInput
                                                        id='endDate'
                                                        label='End Date'
                                                        fieldType={InputTypes.DATE}
                                                        layout={{xs: 6, md: 6, lg: 6}}
                                                        minDate={new Date()}

                                                        size='small'
                                                    />
                                                </>
                                            }
                                        </>
                                    }
                                    <TrancheFundFees commitment={tranche.commitment} values={values}
                                                     formikProps={props}/>
                                    <FormInput
                                        id='comment'
                                        label='Comments'
                                        fieldType={InputTypes.TEXT_BOX}
                                        layout={{xs: 12, md: 12, lg: 12}}

                                        size='small'
                                    />
                                    <Grid item container justifyContent='center' alignItems='center' sx={{p: 0.5}}>
                                        <Button variant='contained' type='submit'>
                                            Confirm
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </>
                    )
                }}
            </Formik>
        </Box>
    )
}

const TrancheFundFees = ({commitment, values, formikProps}: {
    commitment: number,
    values: TrancheFeeValueProps,
    formikProps: FormikProps<any>
}) => {

    const [prevValues, setValues] = useState(values);

    useEffect(() => {
        // Calculate total of funds fees
        let total = 0;
        values.funds.forEach((fund: FundFee) => {
            total += fund.amount
        })

        if ((Math.abs(addValues(total, -values.total)) > 0.1) && (Math.abs(addValues(prevValues.total, -values.total)) > 0.1)) {
            values.funds.forEach((fund: FundFee, f: number) => {
                formikProps.setFieldValue(`funds[${f}].amount`, (values.total * fund.percentage))
            })
            setValues(values);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.total])

    useEffect(() => {
        // Calculate total of funds fees
        if (formikProps.touched.funds) {
            let total = 0;
            values.funds.forEach((fund: FundFee) => {
                total += fund.amount
            })

            if (total !== values.total && prevValues.total !== total) {
                formikProps.setFieldValue(`total`, total);
                setValues(values);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.funds, formikProps.touched])


    return (
        <Grid item md={12} sx={{p: 1}}>
            <Table sx={{border: 2, borderColor: 'primary.main'}}>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{
                            p: '4px',
                            px: 2,
                            border: 'none',
                            bgcolor: 'primary.main',
                            borderColor: 'primary.main',
                            color: 'white',
                            width: 120
                        }}>
                            Fund
                        </TableCell>
                        <TableCell sx={{
                            p: '4px',
                            px: 2,
                            border: 'none',
                            bgcolor: 'primary.main',
                            borderColor: 'primary.main',
                            color: 'white'
                        }} align='right'>
                            Commitment
                        </TableCell>
                        <TableCell sx={{
                            p: '4px',
                            px: 2,
                            border: 'none',
                            bgcolor: 'primary.main',
                            borderColor: 'primary.main',
                            color: 'white'
                        }} align='right'>
                            Fee
                        </TableCell>
                        <TableCell sx={{
                            p: '4px',
                            px: 2,
                            border: 'none',
                            bgcolor: 'primary.main',
                            borderColor: 'primary.main',
                            color: 'white',
                            width: 80
                        }} align='right'/>
                        <TableCell sx={{
                            p: '4px',
                            px: 2,
                            border: 'none',
                            bgcolor: 'primary.main',
                            borderColor: 'primary.main',
                            color: 'white',
                            width: 120
                        }} align='right'>
                            Rate
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell sx={{p: 0, px: 2, borderBottom: 2}}>
                            Metrics Total
                        </TableCell>
                        <TableCell sx={{p: 0, px: 2, borderBottom: 2}} align='right'>
                            {fCurrency(commitment)}
                        </TableCell>
                        <TableCell sx={{p: 0, px: 2, borderBottom: 2, bgcolor: 'grey.200'}} align='right'>
                            <FormInput
                                id='total'
                                label=''
                                fieldType={InputTypes.CURRENCY}
                                layout={{xs: 12, md: 12, lg: 12}}

                                size='small'
                                variant='standard'
                                // noUnderline
                                noLabel
                            />
                        </TableCell>
                        <TableCell sx={{p: 0, px: 2, borderBottom: 2}} align='left'>
                            {determineAdjustmentDiscountPremium(values.transactionType, values.total)}
                        </TableCell>
                        <TableCell sx={{p: 0, px: 2, borderBottom: 2}} align='right'>
                            {fPercent(values.total / commitment)}
                        </TableCell>
                    </TableRow>
                    {values.funds.map((fund, f) => (
                        <TableRow key={f}>
                            <TableCell
                                sx={{p: 0, px: 2, border: 'none', ...((!fund.valuation) ? {bgcolor: 'grey.400'} : {})}}>
                                {fund.fund}
                            </TableCell>
                            <TableCell
                                sx={{p: 0, px: 2, border: 'none', ...((!fund.valuation) ? {bgcolor: 'grey.400'} : {})}}
                                align='right'>
                                {fCurrency(fund.commitment)}
                            </TableCell>
                            <TableCell sx={{p: 0, px: 2, border: 'none', bgcolor: 'grey.200'}} align='right'>
                                <FormInput
                                    id={`funds[${f}].amount`}
                                    label=''
                                    fieldType={InputTypes.CURRENCY}
                                    layout={{xs: 12, md: 12, lg: 12}}

                                    size='small'
                                    variant='standard'
                                    // noUnderline
                                    noLabel
                                />
                            </TableCell>
                            <TableCell sx={{p: 0, px: 2, border: 'none'}} align='left'>
                                {determineAdjustmentDiscountPremium(values.transactionType, values.funds[f].amount)}
                            </TableCell>
                            <TableCell
                                sx={{p: 0, px: 2, border: 'none', ...((!fund.valuation) ? {bgcolor: 'grey.400'} : {})}}
                                align='right'>
                                {fPercent(fund.amount / fund.commitment)}
                            </TableCell>
                        </TableRow>
                    ))

                    }

                </TableBody>
            </Table>

        </Grid>
    )
}



export default TrancheFeeForm;