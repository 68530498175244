import {CircularProgress, Container, Grid, Typography} from "@mui/material";
import {useAppSelector} from "../../store/store";
import {getValuationModelLoading} from "../../store/valuationModel/selectors/generalSelectors";
import {CurveExportColumn, thirdPartyExportSelector} from "../../store/valuationModel/selectors/thirdPartyDataSelector";
import React from "react";
import {fPercent} from "../../utils/formatNumber";
import {formatDate} from "../../utils/DateUtils";
import {Page} from "../../layouts/components";
import {DataTable, InfoTable} from "../../components";


export default function ThirdPartyDataExport() {
    const thirdPartyData = useAppSelector(state => thirdPartyExportSelector(state));
    const loading = useAppSelector(state => getValuationModelLoading(state));

    console.log(thirdPartyData.curves)

    return (
        <Page title="Metrics - Valuation Model - Data">
            <Container maxWidth={false} sx={{maxWidth: '100%', height: '100%'}}>
                <Grid container direction='column' sx={{maxWidth: '100%', height: 1, flex: 1}}>
                    <Grid container direction='row'>
                        <Typography variant="h4" sx={{mb: 3, color: 'primary.main'}}>
                            Data (as of {formatDate(thirdPartyData.valuationDate || new Date(), 'dd-MMM-yyyy')})
                        </Typography>
                    </Grid>
                    <Grid item container direction="row"
                          sx={{
                              width: '100%',
                              flex: 1,
                              overflowY: 'hidden'
                          }}
                    >
                        <Grid item container xs={2} direction='row' alignContent={'flex-start'} sx={{p: 2}}>
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    bgcolor: 'common.white',
                                    border: 2,
                                    borderRadius: 1,
                                    p: 2,
                                    borderColor: 'primary.main'
                                }}
                            >
                                <Grid item xs={12}>
                                    <Typography variant="h5" sx={{mb: 2, color: 'primary.main'}}>
                                        BBSW: {fPercent((thirdPartyData.bbsw || 0) / 100)}
                                    </Typography>
                                </Grid>
                                {loading &&
                                    <Grid item xs={12} alignItems='center' justifyContent='center'
                                          alignContent='center'>
                                        <CircularProgress/>
                                    </Grid>
                                }
                                <Grid item xs={12}>
                                    <Typography variant="h5" sx={{mb: 2, color: 'primary.main'}}>
                                        FX Rates
                                    </Typography>
                                </Grid>
                                {loading &&
                                    <Grid item xs={12} alignItems='center' justifyContent='center'
                                          alignContent='center'>
                                        <CircularProgress/>
                                    </Grid>
                                }
                                <Grid item xs={12}>
                                    <InfoTable data={
                                        thirdPartyData.fxRates.map(({currency, rate}) => ({
                                            label: currency,
                                            value: rate.toString()
                                        }))
                                    }/>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/**/}
                        <Grid item xs={10} container direction='column'
                              sx={{
                                  height: 1
                              }}
                        >
                            <Grid item sx={{width: '100%'}}>
                                <Typography variant="h5" sx={{color: 'primary.main'}}>
                                    Pricing Curves
                                </Typography>
                            </Grid>
                            <Grid item
                                  sx={{
                                      overflowY: 'auto', flex: 1,
                                      bgcolor: 'common.white',
                                      border: 2,
                                      borderRadius: 1,
                                      borderColor: 'primary.main'
                                  }}
                            >
                                <Grid container direction='row'>
                                    {loading &&
                                        <CircularProgress/>
                                    }
                                    {thirdPartyData.curves.map(({label, data}) =>
                                        <Grid item key={label} md={6}
                                              sx={{height: 400, bgcolor: 'common.white', p: 0.5}}>
                                            <DataTable
                                                showExport={false}
                                                title={label}
                                                columns={CurveExportColumn}
                                                data={data}
                                                loading={loading}
                                                sort={{field: 'id', sort: 'asc'}}

                                            />
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Page>
    )
}