import {Adjustment, AxcessLoanCompare, Valuation} from "../../../../types/valuationModelTypes";
import _ from 'lodash';
// MUI
import {AppBar, Box, Grid, SxProps, Tab, Tabs} from "@mui/material";
import {fCurrency, fNumber, fPercent} from "../../../../utils/formatNumber";
import {addValues} from "../../../../utils/mathUtil";
import React, {useState} from "react";
import {TabContext, TabPanel} from "@mui/lab";
import {AdjustmentType, SaveStatus} from "../../../../types/valuationModelEnums";
import {formatDate} from "../../../../utils/DateUtils";
//Components
import {InfoTable} from "../../../../components";
import {BorrowerCommitment} from "../../../../store/valuationModel/selectors/portfolioSelector";


/**
 * Shows summary valuation information for tranche including previous fund split
 * @param trancheId
 * @param tranche
 * @constructor
 */
const Summary = ({
                     tranche,
                     borrower,
                     valuations,
                     adjustments
                 }: {
    tranche: AxcessLoanCompare,
    borrower: BorrowerCommitment | null,
    valuations: Array<Valuation>,
    adjustments: Array<Adjustment>
}) => {

    const [selectedPanel, setPanel] = useState<string>(valuations.length > 0 ? valuations[0].fund : '');
    const [splitPanel, setSplit] = useState<'TRANCHE' | 'BORROWER'>('TRANCHE');

    return (
        <Grid container direction="row">
            <Grid item md={6} padding={1}>
                {valuations.length > 0 &&
                    <TabContext value={selectedPanel}>
                        <AppBar position={'static'} elevation={0}>
                            <Tabs
                                value={selectedPanel}
                                onChange={(event, newValue) => setPanel(newValue)}
                                component={Box}
                                sx={{
                                    textColor: 'primary.main',
                                    bgcolor: 'common.white'
                                }}
                            >
                                {valuations.map((valuation, v) => (
                                    <Tab
                                        label={valuation.fund}
                                        value={valuation.fund}
                                        sx={{
                                            color: 'grey.600',
                                            p: 1
                                        }}
                                        key={v}
                                    />
                                ))}
                            </Tabs>
                        </AppBar>
                        {valuations.map((valuation, v) => (
                            <TabPanel value={valuation.fund} key={v}>
                                <SummaryTable valuation={valuation} adjustments={adjustments}/>
                            </TabPanel>
                        ))}
                    </TabContext>
                }
            </Grid>
            <Grid item md={6} padding={1}>
                <TabContext value={splitPanel}>
                    <AppBar position={'static'} elevation={0}>
                        <Tabs
                            value={splitPanel}
                            onChange={(event, newValue) => setSplit(newValue as 'TRANCHE' | 'BORROWER')}
                            component={Box}
                            sx={{
                                textColor: 'primary.main',
                                bgcolor: 'common.white'
                            }}
                        >
                            <Tab
                                label={'Tranche Fund Split'}
                                value={'TRANCHE'}
                                sx={{
                                    color: 'grey.600',
                                    p: 1
                                }}
                            />
                            <Tab
                                label={'Borrower Fund Split'}
                                value={'BORROWER'}
                                sx={{
                                    color: 'grey.600',
                                    p: 1
                                }}
                            />
                        </Tabs>
                    </AppBar>
                    <TabPanel value={'TRANCHE'}>
                        <FundBorrowerTable funds={tranche.funds} fundsBefore={tranche.funds_before} type={"TRANCHE"}/>
                    </TabPanel>
                    <TabPanel value={'BORROWER'}>
                        {borrower &&
                            <FundBorrowerTable funds={borrower.funds} fundsBefore={borrower.beforeFunds} type={"BORROWER"}/>
                        }
                    </TabPanel>
                </TabContext>
            </Grid>
        </Grid>
    )
}

/**
 * Summary table shows tranche valuation information.
 * @constructor
 */
const SummaryTable = ({valuation, adjustments}: { valuation: Valuation, adjustments: Array<Adjustment> }) => {

    const adjustment = {
        upfrontFees: 0,
        fees: 0,
        transferReceived: 0,
        transferPaid: 0,
        accruals: 0
    }

    adjustments.forEach(a => {
        if (a.status !== SaveStatus.REMOVED && valuation.fund === a.fund) {
            switch (a.transactionType) {
                case AdjustmentType.NEW_ASSET:
                    adjustment.upfrontFees = a.amount;
                    break;
                case AdjustmentType.FEE_RECEIVED:
                    adjustment.fees = addValues(adjustment.fees, -Math.abs(a.amount));
                    break;
                case AdjustmentType.ASSET_PURCHASE:
                    adjustment.transferReceived = addValues(adjustment.transferReceived, a.amount);
                    break;
                case AdjustmentType.ASSET_SALE:
                    adjustment.transferPaid = addValues(adjustment.transferPaid, a.amount);
                    break;
                case AdjustmentType.PL_ACCRUAL:
                    adjustment.accruals = a.amount;
                    break;
            }
        }
    })


    const data: Array<{ label: string, value: string, sx?: SxProps }> = [
        {label: 'Prior Close Premium/(Discount)', value: fCurrency(valuation.openingDiscount)},
        {
            label: 'After Upfront Fees',
            value: fCurrency(adjustment.upfrontFees || valuation.openingDiscount),
            sx: {borderBottom: 1}
        },
        {
            label: 'Fees Received',
            value: fCurrency(adjustment.fees)
        },
        {
            label: 'Fees Received (Asset Transfer)',
            value: fCurrency(adjustment.transferReceived)
        },
        {
            label: 'Fees Paid (Asset Transfer)',
            value: fCurrency(adjustment.transferPaid),
            sx: {borderBottom: 1}
        },
        {
            label: 'Adjusted Opening Premium/(Discount)',
            value: fCurrency(valuation.adjustedOpeningBalance),
            sx: {borderBottom: 2}
        },

        {
            label: `Prior Accruals (since ${formatDate(valuation.previousDate, 'dd-MMM-yy')})`,
            value: fCurrency(valuation.previousAccruals),
            sx: {pt: 2}
        },


        {
            label: `Opening Premium/(Discount) (${formatDate(valuation.valuationDate, 'dd-MMM-yy')})`,
            value: fCurrency(valuation.openingDiscountValuationDate),
            sx: {pt: 2}
        },
        {
            label: 'Commitment Change $',
            value: fCurrency(addValues(valuation.commitment, -valuation.previousCommitment))
        },
        {
            label: 'Commitment Change %',
            value: fPercent((valuation.previousCommitment) ? addValues(valuation.commitment, -valuation.previousCommitment) / valuation.previousCommitment : ((valuation.commitment) ? 1 : 0))
        },
        {
            label: 'Repayment Driven P&L',
            value: fCurrency((valuation.commitment < valuation.previousCommitment) ? addValues(valuation.commitment, -valuation.previousCommitment) / valuation.previousCommitment * valuation.openingDiscountValuationDate : 0)
        },
        {
            label: 'Less Fees Paid (Trade Discount)',
            value: fCurrency(adjustment.transferPaid * -1),
            sx: {borderBottom: 1}
        },
        {label: 'Net Repayment Based P&L', value: fCurrency(valuation.netRepaymentBasedPL), sx: {borderBottom: 2}},

        {
            label: 'Post Repayment Adjusted Premium/(Discount)',
            value: fCurrency(valuation.postRepaymentAdjustedDiscount),
            sx: {py: 2}
        },

        {
            label: 'Accrued Profit/(Loss)',
            value: fCurrency(adjustment.accruals)
        },
        {label: 'Applied Profit/(Loss)', value: fCurrency(valuation.appliedPl), sx: {borderBottom: 1}},
        {label: 'Closing Premium/(Discount)', value: fCurrency(valuation.closingDiscount), sx: {borderBottom: 2}},
        {label: 'Carry', value: (valuation.carry) ? fNumber(valuation.carry) : "-"},
    ]

    return (
        <InfoTable data={data}/>
    )
}

const FundBorrowerTable = ({funds, fundsBefore, type}: { funds: Array<{fund: string, commitment: number}>, fundsBefore?: Array<{fund: string, commitment: number}>, type: 'TRANCHE' | 'BORROWER'}) => {
    let data: Array<{ label: string, value: string, sx?: SxProps }> = [
        {label: `${type === 'TRANCHE' ? 'Tranche' : 'Borrower'} Fund Split`, value: '', sx: {borderBottom: 1}}
    ]

    let totalCurrent = 0;

    let different = false;

    _.cloneDeep(funds).sort((a, b) => (a.fund > b.fund) ? 1 : -1).forEach(fund => {
        data.push({label: fund.fund, value: fCurrency(fund.commitment)});
        totalCurrent = addValues(totalCurrent, fund.commitment);

        if (fundsBefore) {
            const check = fundsBefore.find(beforeFund => beforeFund.fund === fund.fund)
            if (check?.commitment !== fund.commitment) {
                different = true;
            }
        }
    })

    data.push({label: 'Total Commitment', value: fCurrency(totalCurrent), sx: {borderTop: 1}})

    let previousFunds: Array<{ label: string, value: string, sx?: SxProps }> = [
        {label: `Previous ${type === 'TRANCHE' ? 'Tranche' : 'Borrower'} Fund Split`, value: '', sx: {borderBottom: 1}}
    ]
    let totalPrevious = 0;

    if (different) {
        (_.cloneDeep(fundsBefore || []).sort((a, b) => (a.fund > b.fund) ? 1 : -1)).forEach(fund => {
            previousFunds.push({label: fund.fund, value: fCurrency(fund.commitment)})
            totalPrevious = addValues(totalPrevious, fund.commitment)
        })

        previousFunds.push({label: 'Total Commitment', value: fCurrency(totalPrevious), sx: {borderTop: 1}})
    }


    return (
        <>
            <Box
                sx={{
                    bgcolor: 'warning.lighter',
                }}
            >
                {different &&
                    <InfoTable data={previousFunds}/>
                }
            </Box>
            <Box>
                <InfoTable data={data}/>
            </Box>
        </>
    )
}

export default Summary;