import React, {useState} from "react";
import {nanoid} from "@reduxjs/toolkit";
// LOCAL
import {ScheduledAdjustment} from "../../../types/valuationModelTypes";
import {AdjustmentType} from "../../../types/valuationModelEnums";
// @MUI
import {Button, Grid, Typography} from "@mui/material";
import {Delete as DeleteIcon, Save as SaveIcon} from "@mui/icons-material";
// STORE
import {getValuationFunds} from "../../../store/valuationModel/selectors/generalSelectors";
import {useAppSelector} from "../../../store/store";


type RawTransfer = {
    trancheId: number,
    date: Date,
    transferAmount: number,
    saleFund: string,
    purchaseFund: string,
    discount: number
}

/**
 * Handles the upload of CSV transfer template and returns expected scheduled adjustments.
 * @constructor
 */
const TransferTemplateReader = ({setTransactions, save}: {
    setTransactions: (adjustments: Array<ScheduledAdjustment>) => void,
    save: () => void
}) => {
    const valuationFunds = useAppSelector(state => getValuationFunds(state));
    const actions = useAppSelector(state => state.user.user?.permissions.actions.map(a => a.permission));

    const [selectedFile, setSelectedFile] = useState<File | null>(null);

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            const file = files[0];
            const fileType = file.type;
            if (fileType === 'text/csv') {
                setSelectedFile(file);
                const transfers = await handleUpload(file);
                const adjustments = convertTransactionsToScheduledAdjustment(transfers, valuationFunds)
                if (adjustments.length > 0) {
                    setTransactions(adjustments)
                } else {
                    setSelectedFile(null)
                }
            } else {
                setSelectedFile(null);
                alert('Please select a CSV file.');
            }
        }
    };

    const handleClearFile = () => {
        setSelectedFile(null);
        setTransactions([])
    };

    return (
        <>
            <Grid container
                  direction={'column'}
                  spacing={2}
            >  {actions?.some(a => a === 'vm_edit_all') &&
                <Grid item container direction='row' justifyContent='center'>

                    <input
                        type="file"
                        accept=".csv"
                        onChange={handleFileChange}
                        style={{display: 'none'}}
                        id="file-input"
                    />
                    <label htmlFor="file-input">
                        <Button variant="contained" component="span">
                            Upload Transfer Template
                        </Button>
                    </label>

                </Grid>
            }
                <Grid item container direction='row' justifyContent='space-around' alignItems='space-between'>
                    {selectedFile && (
                        <>
                            <Grid item>
                                <Typography variant="subtitle1">Selected File:</Typography>
                                <Typography variant="body1">{selectedFile.name}</Typography>
                            </Grid>
                            <Grid item>
                                <Button variant="outlined" onClick={handleClearFile} sx={{mx: 1}} startIcon={<DeleteIcon/> }>
                                    Clear
                                </Button>
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        save()
                                        setSelectedFile(null)
                                    }}
                                    sx={{mx: 1}}
                                    startIcon={<SaveIcon/>}
                                >
                                    Add
                                </Button>
                            </Grid>
                        </>
                    )}
                </Grid>
            </Grid>
        </>
    )
}

export default TransferTemplateReader;


// handles processing of the file to convert to data
function handleUpload(file: File): Promise<Array<RawTransfer>> {
    return new Promise((resolve, reject) => {
        let parsedTransactions: Array<RawTransfer> = [];

        const reader = new FileReader();
        reader.onload = async (event) => {
            const csvData = event.target?.result as string;

            const parsedData = parseData(csvData);


            for (let t in parsedData) {
                const item = parsedData[t];
                try {
                    // Breakdown AU date format (dd/mm/yyyy)
                    const [day, month, year] = item['Effective Date'].split('/');

                    parsedTransactions.push({
                        trancheId: item['Tranche ID'],
                        date: new Date(`${year}-${month}-${day}`),
                        transferAmount: Number(item['Transfer Amount'].replace(/[^0-9.-]+/g, "")),
                        saleFund: item['Transfer Out Fund'],
                        purchaseFund: item['Transfer In Fund'],
                        discount: Number(item['Discount'].replace(/[^0-9.-]+/g, ""))
                    })
                } catch (e) {
                    console.log(e)
                }
            }
            resolve(parsedTransactions)
        };
        reader.onerror = () => {
            reject([])
        }

        reader.readAsText(file)
    })

}

// handles parsing of the data from CSV to raw data
function parseData(csvData: string) {
    const lines = csvData.split('\n');
    const headers = lines[0].split(',').map((header) => header.replace('\r', ''));

    const data: any[] = [];
    for (let i = 1; i < lines.length; i++) {
        const currentLine = lines[i].split(',').map((header) => header.replace('\r', ''));

        const row: any = {};

        let columnIndex = 0;
        let currentField = '';

        if (currentLine[0] !== '') {
            for (const field of currentLine) {
                if (field.startsWith('"') && !field.endsWith('"')) {
                    // Start of a field with comma(s)
                    currentField += field.slice(1) + ',';
                } else if (!field.startsWith('"') && field.endsWith('"')) {
                    // End of a field with comma(s)
                    currentField += field.slice(0, -1);
                    row[headers[columnIndex]] = currentField;
                    currentField = '';
                    columnIndex++;
                } else if (currentField) {
                    // Inside a field with comma(s)
                    currentField += field + ',';
                } else {
                    // Regular field without comma(s)
                    row[headers[columnIndex]] = field;
                    columnIndex++;
                }
            }
            data.push(row);
        }
    }
    return data;
}


function convertTransactionsToScheduledAdjustment(transfers: Array<RawTransfer>, funds: Array<string>): Array<ScheduledAdjustment> {
    let adjustments: Array<ScheduledAdjustment> = [];

    transfers.forEach(transfer => {
        if (!!transfer.discount) {
            if (funds.includes(transfer.saleFund)) {
                adjustments.push({
                    id: nanoid(),
                    trancheId: transfer.trancheId,
                    amount: transfer.discount,
                    endDate: null,
                    fund: transfer.saleFund,
                    startDate: transfer.date,
                    transactionType: AdjustmentType.ASSET_SALE,
                    comment: "Asset Sale created from scheduled funds transfer",
                })
            }
            if (funds.includes(transfer.purchaseFund)) {
                adjustments.push({
                    id: nanoid(),
                    trancheId: transfer.trancheId,
                    amount: -transfer.discount,
                    endDate: null,
                    fund: transfer.purchaseFund,
                    startDate: transfer.date,
                    transactionType: AdjustmentType.ASSET_PURCHASE,
                    comment: "Asset Purchase created from scheduled funds transfer",
                })
            }
        }
    })

    return adjustments;
}