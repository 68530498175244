import React, {ReactElement, useState} from "react";
import {fCurrency} from "../../../../utils/formatNumber";
import {formatDate} from "../../../../utils/DateUtils";
// MUI
import {
    AppBar,
    Box, Grid,
    Tab,
    Table, TableBody,
    TableCell,
    TableRow,
    Tabs,
    Typography
} from "@mui/material";
import {
    TabContext,
    TabPanel
} from "@mui/lab";
import {
    Block as BlockIcon,
    FiberNew as NewIcon,
    HelpCenter as NotFoundIcon,
    NewReleases as WarningIcon
} from "@mui/icons-material";
// Store
import {useAppSelector} from "../../../../store/store";
import {
    BorrowerCommitment,
    getTranches,
    retrieveBorrowerByTranche
} from "../../../../store/valuationModel/selectors/portfolioSelector";
import {AxcessLoanCompare} from "../../../../types/valuationModelTypes";
// Components
import Summary from "./Summary";
import FeesAdjustments from "./FeesAdjustments";
import TrancheMisc from "./TrancheMisc";
import {selectValuationByTranche} from "../../../../store/valuationModel/selectors/loanWizardSelector";
import {selectAdjustmentFeesForTranche} from "../../../../store/valuationModel/selectors/adjustmentFeesSelector";

/**
 * LoanWizard or Tranche Wizard shows the summary of the tranche. This panel shows the information and also allows for
 * configuring and adding information for the loan.
 * @param trancheId
 * @constructor
 */
const LoanWizard = ({trancheId}: { trancheId: number }) => {
    const tranche = useAppSelector(state => getTranches(state, trancheId));
    const valuations = useAppSelector(state => selectValuationByTranche(state, trancheId));
    const adjustments = useAppSelector(state => selectAdjustmentFeesForTranche(state, trancheId));
    const borrower = useAppSelector(state => retrieveBorrowerByTranche(state, trancheId));

    const [selectedPanel, setPanel] = useState<string>("1");

    return (
        <Box
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column'
            }}
        >
            {!tranche ?
                <Box
                    sx={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <NotFoundIcon
                        sx={{
                            color: 'grey.400',
                            fontSize: 200,
                        }}
                    />
                    <Typography
                        sx={{
                            color: 'grey.400',
                        }}
                        variant='h3'
                    >
                        Tranche Not Found.
                    </Typography>
                </Box>
                :
                <>
                    <TrancheBanner tranche={tranche}/>
                    <TrancheInformation tranche={tranche} borrower={borrower}/>
                    <Box
                        sx={{
                            border: 1,
                            borderColor: 'primary.main',
                            flex: 1,
                        }}
                    >
                        <TabContext value={selectedPanel}>
                            <AppBar position={'static'}>
                                <Tabs
                                    value={selectedPanel}
                                    onChange={(event, newValue) => setPanel(newValue)}
                                    textColor="inherit"
                                    indicatorColor='primary'
                                >
                                    <Tab label="Summary" value="1"/>
                                    <Tab label="Fees & Adjustments" value="2"/>
                                    <Tab label="Misc." value="3"/>
                                </Tabs>
                            </AppBar>
                            <TabPanel value="1" sx={{height: '92%'}}><Summary tranche={tranche} borrower={borrower}
                                                                              valuations={valuations}
                                                                              adjustments={adjustments}/></TabPanel>
                            <TabPanel value="2" sx={{height: '92%'}}><FeesAdjustments trancheId={trancheId}
                                                                                      tranche={tranche}/></TabPanel>
                            <TabPanel value="3" sx={{height: '92%'}}><TrancheMisc trancheId={trancheId}
                                                                                  tranche={tranche}/></TabPanel>
                        </TabContext>
                    </Box>
                </>
            }
        </Box>
    )
}

const TrancheBanner = ({tranche}: { tranche: AxcessLoanCompare }) => {
    return (
        <>
            {(tranche.new) &&
                <Banner icon={<NewIcon/>} text='New Loan.' color='success.light'/>
            }
            {(tranche.removed) &&
                <Banner icon={<BlockIcon/>} text='Loan has been closed.' color='error.light'/>

            }
            {(Object.keys(tranche.change).length > 0) &&
                <Banner icon={<WarningIcon/>} text='Loan has been modified.' color='warning.light'/>
            }
        </>
    )
}

const Banner = ({icon, text, color}: { icon: ReactElement, text: string, color: string }) => {
    return (
        <Box
            sx={{
                p: 2,
                mb: 1,
                borderRadius: 1,
                bgcolor: color,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            {React.cloneElement(icon, {sx: {color: 'grey.700'}})}
            <Typography
                variant='h6'
                sx={{
                    pl: 1,
                    color: 'grey.700',
                }}
            >
                {text}
            </Typography>
        </Box>
    )
}

const TrancheInformation = ({tranche, borrower}: {
    tranche: AxcessLoanCompare,
    borrower: BorrowerCommitment | null
}) => {

    const information = [
        {label: 'Tranche ID', info: tranche.tranche_id},
        {label: 'Tranche Name', info: tranche.tranche},
        {label: 'Borrower', info: tranche.borrower},
        {label: 'Asset Name', info: tranche.asset},
        {label: 'Maturity', info: formatDate(tranche.maturity, 'dd-MMM-yyyy')},
        {label: 'Tranche Commitment', info: fCurrency(tranche.commitment)},
        (borrower) ? {
            label: 'Agg. Borrower Commit',
            info: fCurrency(borrower.commitment),
            sx: {fontStyle: 'italic'}
        } : {}
    ]

    const informationChanges = [
        {label: 'Previous Tranche Information', info: ''},
        {label: ' ', info: ' '},
        {label: ' ', info: ' '},
        {label: ' ', info: ' '},
        (tranche.change.maturity ? {
            label: 'Maturity',
            info: formatDate(tranche.change.maturity, 'dd-MMM-yyyy')
        } : {label: ' ', info: ' '}),
        (tranche.change.commitment ? {
            label: 'Commitment',
            info: fCurrency(tranche.change.commitment as number)
        } : {label: ' ', info: ' '}),
        (borrower && borrower.beforeCommitment) ? {
            label: 'Agg. Borrower Commit',
            info: fCurrency(borrower.beforeCommitment)
        } : {}
    ]

    return (
        <Box
            sx={{
                pl: 10,
                p: 2
            }}
        >
            <Grid container spacing={2}>
                <Grid item md={6}>
                    <Box
                        sx={{
                            p: 2
                        }}
                    >
                        <Table>
                            <TableBody>
                                {information.map((row, i) => (
                                    <TableRow key={i}>
                                        <TableCell
                                            sx={{
                                                color: 'primary.main',
                                                fontSize: 15,
                                                p: 0,
                                                border: 'none',
                                                // width: 180,
                                                ...row.sx
                                            }}
                                        >
                                            <b>{row.label}:</b>
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                fontSize: 15,
                                                flex: 1,
                                                p: 0,
                                                border: 'none',
                                                ...row.sx
                                            }}
                                        >
                                            {row.info}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Box>
                </Grid>
                {(Object.keys(tranche.change).length > 0 || (borrower && borrower.commitment !== borrower.beforeCommitment)) &&
                    <Grid item md={6}>
                        <Box
                            sx={{
                                p: 2,
                                bgcolor: 'warning.lighter'
                            }}
                        >
                            <Table>
                                <TableBody>
                                    {informationChanges.map((row, i) => (
                                        <TableRow key={i}>
                                            <TableCell
                                                sx={{
                                                    color: 'primary.main',
                                                    fontSize: 15,
                                                    p: 0,
                                                    border: 'none',
                                                    height: 25
                                                }}
                                            >
                                                <b>{row.label}{(row.label !== ' ') && ':'}</b>
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    fontSize: 15,
                                                    flex: 1,
                                                    p: 0,
                                                    border: 'none'
                                                }}
                                            >
                                                {row.info}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Grid>
                }

            </Grid>

        </Box>
    )
}

export default LoanWizard;