import axios, {isAxiosError} from 'axios'
import {addNotification} from "./notifications/notificationSlice";
import {GetThunkAPI} from "@reduxjs/toolkit/dist/createAsyncThunk";
import {setUnauthenticated} from "./user/userFunction";

const axiosInstance = {
    auth: axios.create({
        baseURL: process.env.REACT_APP_AWS_MCP_API_AUTH,
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_AWS_MCP_API_KEY_AUTH
        },
        withCredentials: true
    }),
    mipad: axios.create({
        baseURL: process.env.REACT_APP_AWS_MCP_API_MIPAD,
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_AWS_MCP_API_KEY_MIPAD
        },
        withCredentials: true
    })
}

// GENERIC HANDLER FOR AUTH API GET REQUESTS
export async function apiAuthGetRequests(query: string): Promise<any> {
    const response = await axiosInstance.auth.get(query);
    return response.data;
}

// GENERIC HANDLER FOR AUTH API GET REQUESTS
export async function apiAuthPostRequests(query: string, body?: any): Promise<any> {
    const response = await axiosInstance.auth.post(query, body);
    return response.data;
}

// -----------------------------------------------------------------------------------------------------------
// Generic functions for handling API requests

// GENERIC HANDLER FOR ALL API GET REQUESTS
export async function apiGetRequests(query: string, thunkAPI?: GetThunkAPI<any>): Promise<any> {
    try {
        const response = await axiosInstance.mipad.get(query);
        return response.data;
    } catch (error) {
        if (thunkAPI) {
            throw apiErrorExpiryCheck(error, thunkAPI)
        } else {
            throw error
        }
    }
}

// GENERIC HANDLER FOR ALL API POST REQUESTS
export async function apiPostRequests(query: string, body?: any, thunkAPI?: GetThunkAPI<any>): Promise<any> {
    try {
        const response = await axiosInstance.mipad.post(query, body);
        return response.data;
    } catch (error) {
        if (thunkAPI) {
            throw apiErrorExpiryCheck(error, thunkAPI)
        } else {
            throw error
        }
    }
}

// GENERIC HANDLER FOR ALL API PUT REQUESTS
export async function apiPutRequests(query: string, body?: any, thunkAPI?: GetThunkAPI<any>): Promise<any> {
    try {
        const response = await axiosInstance.mipad.put(query, body);
        return response.data;
    } catch (error) {
        if (thunkAPI) {
            throw apiErrorExpiryCheck(error, thunkAPI)
        } else {
            throw error
        }
    }
}

// GENERIC HANDLER FOR ALL API PATCH REQUESTS
export async function apiPatchRequests(query: string, body?: any, thunkAPI?: GetThunkAPI<any>): Promise<any> {
    try {
        const response = await axiosInstance.mipad.patch(query, body);
        return response.data;
    } catch (error) {
        if (thunkAPI) {
            throw apiErrorExpiryCheck(error, thunkAPI)
        } else {
            throw error
        }
    }
}

// GENERIC HANDLER FOR ALL API DELETE REQUESTS
export async function apiDeleteRequests(query: string, body?: any, thunkAPI?: GetThunkAPI<any>): Promise<any> {
    try {
        const response = await axiosInstance.mipad.delete(query, {data: body});
        return response.data;
    } catch (error) {
        if (thunkAPI) {
            throw apiErrorExpiryCheck(error, thunkAPI)
        } else {
            throw error
        }
    }
}

// GENERIC HANDLER FOR MULTIPLE API GET REQUESTS
export async function multipleAsyncGetRequest(queries: { [x: string]: string }, thunkAPI?: GetThunkAPI<any>) {
    const results: { [x: string]: any } = {};

    const queryPromises = Object.entries(queries).map(async ([key, value]) => results[key] = await apiGetRequests(value, thunkAPI));

    await Promise.all(queryPromises)

    return results
}

// GENERIC HANDLER FOR MULTIPLE API POST REQUESTS
export async function multipleAsyncPostRequest(queries: { [x: string]: { query: string, body: any } }, thunkAPI?: GetThunkAPI<any>) {
    const results: { [x: string]: any } = {};

    const queryPromises = Object.entries(queries).map(async ([key, request]) => results[key] = await apiPostRequests(request.query, request.body, thunkAPI));

    await Promise.all(queryPromises)

    return results
}

// CHECK AND HANDLE USER ACCESS EXPIRY
export function apiErrorExpiryCheck(error: unknown, thunkAPI: GetThunkAPI<any>) {
    if (isAxiosError(error)) {
        if (error?.response?.status === 403) {
            thunkAPI.dispatch(setUnauthenticated());
            thunkAPI.dispatch(addNotification('Session expired! Redirecting...', 'warning'));
            return new Error('AUTH_ERROR')
        }
    }

    return error;
}