import {Formik, FormikProps} from "formik";
import _ from "lodash";
import * as Yup from "yup";
import {Adjustment, AxcessLoanCompare, AxcessLoanFund} from "../../../../../../types/valuationModelTypes";
import {fCurrency, fPercent} from "../../../../../../utils/formatNumber";
// MUI
import {Box, Button, Grid, Typography} from "@mui/material";
// Store
import {useAppDispatch, useAppSelector} from "../../../../../../store/store";
import {getValuationFunds} from "../../../../../../store/valuationModel/selectors/generalSelectors";
import {AdjustmentTypeFields} from "../../../../../../types/valuationModelEnums";
import {editAdjustment} from "../../../../../../store/valuationModel/valuationModelSlice";
// Components
import {FormInput} from "../../../../../../components";
import {InputTypes} from "../../../../../../types/InputTypes";

const SingleFeeSchema = Yup.object().shape({
    transactionType: Yup.string().required('Adjustment/Fee Type is required'),
    fund: Yup.string().required('Fund is required'),
    amount: Yup.number()
})

/**
 * Form to update an Adjustment or Fee
 * @param trancheId
 * @param tranche
 * @param adjustment
 * @constructor
 */
const UpdateFeeForm = ({tranche, adjustment, onClose}: {
    tranche: AxcessLoanCompare, adjustment: Adjustment, onClose: () => void
}) => {
    const dispatch = useAppDispatch();
    const funds = useAppSelector(state => getValuationFunds(state));

    const selectFunds = _.cloneDeep([...tranche.funds, ...(tranche.funds_before) ? tranche.funds_before : []])
        .sort((a, b) => (a.fund > b.fund) ? 1 : -1)
        .reduce((fundsArray: Array<AxcessLoanFund>, fund) => {
            if (fundsArray.findIndex(f => f.fund === fund.fund) === -1) {
                if (funds.includes(fund.fund)) fundsArray.push(fund)
            }
            return fundsArray;
        }, []);

    return (
        <Box sx={{width: '100%'}}>
            <Formik
                initialValues={{
                    ...adjustment,
                    ...(!adjustment.isManual) ? {comment: ""} : {}
                }}
                onSubmit={(values) => {
                    dispatch(editAdjustment({
                        ...values,
                    }))
                    onClose()
                }}
                validationSchema={SingleFeeSchema}
            >
                {(props: FormikProps<any>) => {
                    const {
                        handleSubmit,
                        values
                    } = props;

                    let fund: AxcessLoanFund | undefined;

                    if (values.fund) {
                        fund = tranche.funds.find(f => f.fund === values.fund);
                    }

                    return (
                        <>
                            <form onSubmit={handleSubmit}>
                                <Grid item container direction='row'>
                                    <FormInput
                                        id='transactionType'
                                        label='Adjustment/Fee Type'
                                        fieldType={InputTypes.SELECTION}
                                        layout={{xs: 6, md: 6, lg: 6}}
                                        values={AdjustmentTypeFields}

                                        size='small'
                                    />
                                    <FormInput
                                        id='fund'
                                        label='Fund'
                                        fieldType={InputTypes.SELECTION}
                                        layout={{xs: 6, md: 6, lg: 6}}
                                        values={selectFunds.map((fund) => {
                                            return {label: fund.fund, value: fund.fund}
                                        })}
                                        disabled={true}

                                        size='small'
                                    />
                                    <Grid item container md={12} sx={{p: 1}}>
                                        {(fund?.commitment) &&
                                            <>
                                                <Typography variant="h5">
                                                    {values.fund} commitment: {fCurrency(fund.commitment)}
                                                </Typography>
                                            </>
                                        }
                                    </Grid>
                                    <FormInput
                                        id='amount'
                                        label='Amount'
                                        fieldType={InputTypes.CURRENCY}
                                        layout={{xs: 6, md: 6, lg: 6}}
                                        size='small'
                                    />
                                    <Grid item container md={6} justifyContent='center' alignItems='center'>
                                        {(fund?.commitment) &&
                                            <Typography variant="h4">
                                                {fPercent(values.amount / fund.commitment)}
                                            </Typography>
                                        }
                                    </Grid>
                                    <FormInput
                                        id='comment'
                                        label='Comments'
                                        fieldType={InputTypes.TEXT_BOX}
                                        layout={{xs: 12, md: 12, lg: 12}}

                                        size='small'
                                    />
                                    <Grid item container justifyContent='center' alignItems='center' sx={{p: 0.5}}>
                                        <Button variant='contained' type='submit'>
                                            Update
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </>
                    )
                }}
            </Formik>
        </Box>
    )
}

export default UpdateFeeForm;