export enum CurrencyType {
    AUD = 'AUD',
    GBP = 'GBP',
    NZD = 'NZD',
    USD = 'USD'
}

export enum InvestmentType {
    REAL_ESTATE = 'Real Estate',
    CORPORATE = 'Corporate',
    LEVERAGED = 'Leveraged',
    EQUITY = 'Equity',
    FUND_FINANCE = 'Fund Finance',
    PROJECT_FINANCE_INFRASTRUCTURE = 'Project Finance & Infrastructure',
    STRUCTURED = 'Structured',
    OTHER = 'Other'
}

export enum MCPRatings {
    'aa-' = 'aa-',
    'a+' = 'a+',
    'a' = 'a',
    'a-' = 'a-',
    'bbb+' = 'bbb+',
    'bbb' = 'bbb',
    'bbb-' = 'bbb-',
    'bb+' = 'bb+',
    'bb' = 'bb',
    'bb-' = 'bb-',
    'b+' = 'b+',
    'b' = 'b',
    'b-' = 'b-',
    'ccc+' = 'ccc+',
    'ccc' = 'ccc',
    'd' = 'd',
}

export enum Seniority {
    EQUITY = 'Equity',
    OTHER = 'OTHER',
    SENIOR = 'Senior',
    SUBORDINATED = 'Subordindated',
    SUPER_SENIOR = 'Super Senior'
}

export enum SPSector {
    COMMUNICATIONS = 'Communications',
    CONSUMER_DISCRETIONARY = 'Consumer Discretionary',
    CONSUMER_STAPLES = 'Consumer Staples',
    ENERGY = 'Energy',
    FINANCIALS = 'Financials',
    HEALTH_CARE = 'Health Care',
    INDUSTRIALS = 'Industrials',
    INFORMATION_TECHNOLOGY = 'Information Technology',
    MATERIALS = 'Materials',
    REAL_ESTATE = 'Real Estate',
    UTILITIES = 'Utilities'
}

export enum States {
    ACT = 'ACT',
    NSW = 'NSW',
    NT = 'NT',
    OFFSHORE = 'Offshore',
    QLD = 'QLD',
    SA = 'SA',
    TAS = 'TAS',
    VIC = 'VIC',
    WA = 'WA'
}

export enum TrancheTypes {
    TERM_BULLET = 'Term Bullet',
    EQUITY = 'Equity',
    REVOLVING = 'Revolving',
    CONSTRUCTION = 'Construction',
    REVOLVING_MULTI_CURRENCY = 'Revolving Multi-currency',
    CAPEX = 'Capex',
    TERM_AMORTISING = 'Term Amortising',
    GUARANTEE = 'Guarantee',
    OTHER = 'Other'
}